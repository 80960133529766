import React from 'react';
import {
  Icon,
  VStack,
  Box,
  Button,
  Spacer,
  useDisclosure,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { Premium } from '../../../../../types/Offer';
import OfferPremium from '../offers/components/offer/offer-result/OfferPremium';
import AskaAcknowledgementModal from './AskaAcknowledgementModal';

interface Props {
  premium: Premium;
  paymentUrl?: string;
  downloadUrl?: string;
  termsUrl?: string;
  isPaid?: boolean;
}

const OfferPaymentListItemRight: React.FC<Props> = ({
  premium,
  paymentUrl,
  downloadUrl,
  termsUrl,
  isPaid,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const handlePay = () => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  };

  return (
    <>
      <AskaAcknowledgementModal
        isOpen={isOpen}
        onClose={onClose}
        onAgree={handlePay}
        termsUrl={termsUrl}
      />
      <VStack w={{ tabletS: '60', laptop: '72' }} align="stretch" spacing="4">
        <Box py="4" px="6">
          <OfferPremium
            paymentInterval={premium.payment_interval}
            price={premium.total_premium}
            currency={premium.currency}
            policyCosts={premium.recurring_policy_costs}
            risk="travel"
          />
        </Box>
        <Spacer />
        {!isPaid && (
          <Button
            as="a"
            variant="link"
            color="gray.500"
            leftIcon={<Icon name="GeneralActionsDownload" />}
            href={downloadUrl}
            target="_blank"
          >
            {t('Download offer')}
          </Button>
        )}
        {isPaid ? (
          <Button
            variant="successPrimary"
            borderRadius="none"
            pointerEvents="none"
            size="lg"
            w="100%"
            leftIcon={<Icon name="GeneralStatusSuccessCheckmark" />}
          >
            {t('PAYED')}
          </Button>
        ) : (
          <Button borderRadius="none" size="lg" w="100%" onClick={onOpen}>
            {t('OFFERS_FINALIZATION_ACTION_PAY')}
          </Button>
        )}
      </VStack>
    </>
  );
};

export default OfferPaymentListItemRight;
